@tailwind base;
@tailwind components;

@tailwind utilities;

@layer_base {
  @font-face {
    font-family: 'Avenir';
    src: local('Avenir'), url('./assets/fonts/Avenir.ttc') format('ttc');
  }
}
.multi {
  background-image: url('./assets/images/Mask Group 133.png'),
    url('./assets/images/Mask Group 134.png');
  background-repeat: no-repeat, no-repeat;
  background-size: 40em auto, 30em auto;
  background-position: bottom left, top right;
}
.no-event {
  background-image: url('./assets/images/Group 41245.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

/* .logo-oasis {
  background-image: url('./assets/images/Logo_Oasis_Splashscreen.png');
  background-size: contain;
  background-position: bottom;
}
.logo-hca {
  background-image: url('./assets/images/Logo_HCA_Loginpage.png');
  background-size: contain;
} */

.slideContainer {
  @apply my-3 2xl:my-4;
}

.banner {
  background-image: url('https://images.unsplash.com/photo-1624499550873-c7cbd0fe765a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1578&q=80');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.attendBox {
  background: var(---ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -2px 99px #00000029;
  border: 1px solid #d5d5d5;
  border-radius: 18px;
  opacity: 0.9;
}

.masterBackground {
  background: transparent
    linear-gradient(180deg, var(---ffffff) 0%, #f4fff0 100%) 0% 0% no-repeat
    padding-box;
  background: transparent linear-gradient(180deg, #ffffff 0%, #f4fff0 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
}
